<template>
  <div>
    <contract-inquiry-popup-branch
      :temp-search-branch="tempSearchBranch"
      :is-popup-branch-active.sync="isPopupBranchActive"
      @select-item="fncSelectBranch"
    />
    <b-card
      title="เรียกดูรายการสัญญา"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-input-group v-show="!branchCode">
              <b-form-input
                id="branchCode"
                v-model="inputSearchBranch"
                placeholder="ค้นหาพอร์ต"
                trim
                @keydown.enter.native="
                  fncSearchBranch(inputSearchBranch)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupBranch()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="branchCode">
              <b-form-input
                id="branchCode"
                :value="branchName"
                trim
                disabled
              />
              <b-input-group-append v-if="!isDefaultBranchFromLogin">
                <b-button
                  variant="primary"
                  @click="fncCancelBranch()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputSearchRegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค้นหาเลขทะเบียน
              </div>
            </template>
            <b-input-group v-show="!regNum1 && !regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="inputSearchRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
              />
              <b-form-input
                id="inputSearchRegNum2"
                ref="refInputSearchRegNum2"
                v-model="inputSearchRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
              />
            </b-input-group>
            <b-input-group v-show="regNum1 || regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="regNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                disabled
              />
              <b-form-input
                id="inputSearchRegNu2"
                v-model="regNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%; margin-left: 5px;"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNum"
              v-model="InputchassisNum"
              placeholder="หมายเลขตัวถัง"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อลูกค้า"
            label-for="CusName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อลูกค้า
              </div>
            </template>
            <b-form-input
              id="CusName"
              v-model="InputCusName"
              placeholder="ชื่อลูกค้า"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlayLoadDataTable"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                variant="primary"
                @click="fncSubmit()"
              >
                ค้นหาข้อมูล
              </b-button>
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <contract-inquiry-result
          :data-result="dataResult"
        />
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import ContractInquiryStoreModule from './ContractInquiryStoreModule'
import ContractInquiryPopupBranch from './contract-inquiry-popup/ContractInquiryPopupBranch.vue'
import ContractInquiryResult from './ContractInquiryResult.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,

    ContractInquiryPopupBranch,
    ContractInquiryResult,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appContractInquiry'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, ContractInquiryStoreModule)
    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)
    const InputchassisNum = ref(null)
    const InputCusName = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const chassisNum = ref(null)
    const tempSearchBranch = ref(null)
    const tempSearchRegNum = ref(null)
    const dataResult = ref([])
    const isDefaultBranchFromLogin = ref(false)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupRegNumActive = ref(false)

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }
    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlayLoadDataTable = ref(false)

    /* ฟังก์ชั่นค้นหาข้อมูล */
    const fncSubmit = () => {
      overlayLoadDataTable.value = true
      const payload = {
        action: 'LIST',
        events: 'INQUIRY',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        CPNBRNCOD_IN: branchCode.value,
        REGNUM1_IN: inputSearchRegNum1.value,
        REGNUM2_IN: inputSearchRegNum2.value,
        CHASSISNUM_IN: InputchassisNum.value,
        customer_in: InputCusName.value,

      }
      console.log('payloadfncSubmit', payload)

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CONTRACT_MASTER`, payload)
        .then(response => {
          dataResult.value = response.data.responseData

          overlayLoadDataTable.value = false // ปิดการแสดงสถานะ Loading
          console.log('data', response.data.responseData)
        })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      branchCode.value = null
      regNum1.value = null
      regNum2.value = null
      InputchassisNum.value = null
      InputCusName.value = null
      dataResult.value = []
    }

    return {
      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputSearchRegNum1,
      inputSearchRegNum2,
      InputchassisNum,
      InputCusName,
      fncResetForm,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      regNum1,
      regNum2,
      chassisNum,
      tempSearchBranch,
      tempSearchRegNum,
      dataResult,
      isDefaultBranchFromLogin,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupBranch,
      fncSearchBranch,
      fncCancelBranch,
      fncSelectBranch,
      fncSubmit,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupRegNumActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlayLoadDataTable,

    }
  },

}
</script>

<style>

</style>
